import {
  Box,
  Button,
  Container,
  Fab,
  Grid,
  Link,
  useMediaQuery,
} from "@mui/material";
import CarrouselEnterprises from "../components/layout/CarrouselEnterprises";
import Faq from "../components/layout/Faq";
import styles from "./TalentsBank.module.css";

// images
import imgHero from "../imgs/imgHeroRecruiter@2x.png";
import imgList from "../imgs/ListFilteredRecruiter@2x.png";

//icons
import { useTheme } from "@emotion/react";
import {
  CancelRounded,
  CheckCircleRounded,
  GroupsRounded,
  PersonSearchRounded,
  ViewKanbanRounded,
  WhatsApp,
} from "@mui/icons-material";

function TalentsBank() {
  const theme = useTheme();
  const deviceScreenDownMd = useMediaQuery(theme.breakpoints.down("md"));
  // Benefits Item List with Filter Image
  function mountItemList(icon, title, description) {
    return (
      <Grid item lg={12} key={title}>
        <Grid container rowGap={3} className={styles.itemList}>
          <Grid item>
            <Grid container alignItems="center" columnGap={3} rowGap={3}>
              <Grid item className={styles.iconList}>
                {icon}
              </Grid>
              <Grid item lg display={{ xs: "none", sm: "none", md: "flex" }}>
                <h5 className="disableMarginBlock">{title}</h5>
              </Grid>
              <Grid item lg display={{ xs: "flex", sm: "flex", md: "none" }}>
                <h3 className="disableMarginBlock">{title}</h3>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item lg ml={{ xs: 0, sm: 0, md: 11 }}>
              <p className="disableMarginBlock">{description}</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const itemList = [
    {
      icon: <ViewKanbanRounded />,
      title: "Receba e gerencia talentos nos seus processos seletivos",
      description:
        "Tudo em um só lugar! Agilize o seu processo seletivo recebendo a candidatura de talentos que são específicos da sua área, gerencie todo o processo seletivo até a contratação pela nossa plataforma.",
    },

    {
      icon: <PersonSearchRounded />,
      title: "Busque o talento ideal com os nossos filtros avançados",
      description:
        "Aqui sua vaga não precisa esperar o talento vir até você. Tenha em mãos uma busca avançada para varrer o banco de dados procurando pelo talento ideal para a sua vaga.",
    },

    {
      icon: <GroupsRounded />,
      title: "Otimize o tempo com o gerenciador de processos seletivos",
      description:
        "Seu processo ficará muito mais fácil com as funcionalidades do gerenciador, como: Funil de aprovação, favoritar candidatos, comentários internos e email de desclassificação.",
    },
  ];
  // End of mountListItem

  // Mount Plans
  function mountPlans(data) {
    console.log("data", data);
    let borderStylePlans = {};
    const { planTitle, badgeText, descriptionPlan, selected, plan, product } =
      data;

    function mountBadgeChosen(selected) {
      if (selected) {
        return (
          <>
            <Box
              className={styles.badgeRecommendedPlan}
              display={{ xs: "none", sm: "none", md: "flex" }}
            >
              Plano mais escolhido
            </Box>
            <Box
              className={styles.badgeRecommendedPlan}
              display={{ xs: "flex", sm: "flex", md: "none" }}
            >
              Mais escolhido
            </Box>
          </>
        );
      }
    }

    if (!selected) {
      borderStylePlans = {
        border: "solid",
        borderColor: "#CCC",
        borderRadius: "16px",
        borderWidth: "2px 2px 4px 2px",
      };
    } else {
      borderStylePlans = {
        border: "solid",
        borderColor: "#1EBD54",
        borderRadius: "16px",
        borderWidth: "4px 4px 8px 4px",
      };
    }
    return (
      <Grid
        container
        item
        xs={4}
        sm={4}
        lg={3}
        key={planTitle}
        zIndex="2"
        height={{ xs: "1700px", md: "1238px" }}
      >
        <Grid container justifyContent="center" sx={borderStylePlans}>
          <Grid item xs={12} sm={12} lg={12}>
            <Grid
              container
              pt={{ xs: 4, sm: 4, md: 6 }}
              rowGap={5}
              justifyContent="center"
              position="relative"
            >
              {mountBadgeChosen(selected)}

              <Grid item className={styles.badgeVacationNumber}>
                {badgeText}
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                <Grid
                  container
                  justifyContent="center"
                  textAlign="center"
                  px={1}
                  rowGap={1}
                >
                  <Grid item display={{ xs: "none", sm: "none", md: "flex" }}>
                    <h5 className="disableMarginBlock">{planTitle}</h5>
                  </Grid>

                  <Grid
                    container
                    item
                    display={{ xs: "flex", sm: "flex", md: "none" }}
                    height={{ xs: "32px", sm: "32px" }}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <h6 className="disableMarginBlock">{planTitle}</h6>
                  </Grid>
                  <Grid
                    item
                    display={{ xs: "none", sm: "none", md: "flex" }}
                    maxWidth={{ md: "260px" }}
                  >
                    <p className="disableMarginBlock">{descriptionPlan}</p>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                <Grid container px={{ xs: 1, sm: 1, md: 3 }} rowGap={3} mb={4}>
                  {/* <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    lg={12}
                    px={1}
                    className={styles.wrapperPlanPrice}
                    justifyContent={{ xs: "left", sm: "auto", md: "center" }}
                    alignItems={{
                      xs: "flex-start",
                      sm: "flex-start",
                      md: "center",
                    }}
                    flexDirection={{ xs: "column", sm: "column", md: "row" }}
                  >
                    <Grid item>
                      <span className={styles.currencyText}>R$</span>
                    </Grid>
                    <Grid item>
                      <span className={styles.planPriceText}>{planPrice}</span>
                    </Grid>
                    <Grid item>
                      <span className={styles.installmentsText}>
                        (x{installments})
                      </span>
                    </Grid>
                  </Grid> */}
                  <Grid item xs={12} sm={12} lg={12}>
                    <Link
                      href={`${process.env.REACT_APP_CUSTOMER_URL}/new-plan-validation?plan=${plan}&type_plan=${product}`}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth={true}
                        size={deviceScreenDownMd ? "small" : "medium"}
                      >
                        Selecionar Plano
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const plans = [
    {
      planTitle: "ANUAL",
      badgeText: "12 vagas no ano",
      descriptionPlan:
        "Perfeito para empresas com um planejamento de crescimento estabelecido. Conte com a nossa solução para ter currículos completos e contratar mais rápido.",
      planPrice: "369",
      installments: "12",
      selected: false,
      product: 3,
      plan: 6,
    },
    {
      planTitle: "ANUAL ILIMITADO",
      badgeText: "Vagas ilimitadas",
      descriptionPlan:
        "Ideal para empresas com grande crescimento de equipes anualmente. Tenha quantas vagas quiser e com renovação delas na plataforma por tempo ilimitado.",
      planPrice: "1.590",
      installments: "12",
      product: 3,
      plan: 5,
    },
    {
      planTitle: "HÍBRIDO",
      badgeText: "Ilimitada e 3 Vagas no Assessoria",
      descriptionPlan:
        "Ideal para empresas com grande crescimento de equipes anualmente. Tenha quantas vagas quiser e com renovação delas na plataforma por tempo ilimitado.",
      planPrice: "439",
      installments: "12",
      selected: true,
      product: 3,
      plan: 9,
    },
  ];
  // End of Mount Plans

  //custom Table

  //Mount Table Plan
  function mountTablePlan(objPlanCell, i, deviceScreenDownMd) {
    const oddLine = i % 2;

    if (!deviceScreenDownMd) {
      return (
        <Grid
          container
          item
          columnSpacing={3}
          lg={12}
          key={i}
          py={2}
          alignItems={"center"}
          className={
            !oddLine
              ? `${styles.planTableLine} ${styles.planTableOddLine}`
              : `${styles.planTableLine}`
          }
        >
          <Grid item lg={3}>
            <span>{objPlanCell.header}</span>
          </Grid>
          <Grid container item lg={3} justifyContent={"center"}>
            {typeof objPlanCell.content.anualIlimitado === "boolean" ? (
              objPlanCell.content.anualIlimitado ? (
                <CheckCircleRounded className={styles.planIcon} />
              ) : (
                <CancelRounded
                  className={styles.planIcon + " " + styles.cancelIcon}
                />
              )
            ) : (
              <span>{objPlanCell.content.anualIlimitado}</span>
            )}
          </Grid>
          <Grid container item lg={3} justifyContent={"center"}>
            {typeof objPlanCell.content.anual === "boolean" ? (
              objPlanCell.content.anual ? (
                <CheckCircleRounded className={styles.planIcon} />
              ) : (
                <CancelRounded
                  className={styles.planIcon + " " + styles.cancelIcon}
                />
              )
            ) : (
              <span>{objPlanCell.content.anual}</span>
            )}
          </Grid>
          <Grid container item lg={3} justifyContent={"center"}>
            {typeof objPlanCell.content.trimestral === "boolean" ? (
              objPlanCell.content.trimestral ? (
                <CheckCircleRounded className={styles.planIcon} />
              ) : (
                <CancelRounded
                  className={styles.planIcon + " " + styles.cancelIcon}
                />
              )
            ) : (
              <span>{objPlanCell.content.trimestral}</span>
            )}
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          item
          xs={12}
          key={i}
          alignItems={"center"}
          className={styles.planTableLine}
        >
          <Grid
            container
            item
            p={2}
            xs={12}
            className={styles.planTableOddLine}
          >
            <span>{objPlanCell.header}</span>
          </Grid>

          <Grid
            container
            item
            xs={12}
            height={"56px"}
            alignItems={"center"}
            className={styles.planTableLineRowMobile}
          >
            <Grid container item xs={4} justifyContent={"center"}>
              {typeof objPlanCell.content.anualIlimitado === "boolean" ? (
                objPlanCell.content.anualIlimitado ? (
                  <CheckCircleRounded className={styles.planIcon} />
                ) : (
                  <CancelRounded
                    className={styles.planIcon + " " + styles.cancelIcon}
                  />
                )
              ) : (
                <p>{objPlanCell.content.anualIlimitado}</p>
              )}
            </Grid>
            <Grid container item xs={4} justifyContent={"center"}>
              {typeof objPlanCell.content.anual === "boolean" ? (
                objPlanCell.content.anual ? (
                  <CheckCircleRounded className={styles.planIcon} />
                ) : (
                  <CancelRounded
                    className={styles.planIcon + " " + styles.cancelIcon}
                  />
                )
              ) : (
                <p>{objPlanCell.content.anual}</p>
              )}
            </Grid>
            <Grid container item xs={4} justifyContent={"center"}>
              {typeof objPlanCell.content.trimestral === "boolean" ? (
                objPlanCell.content.trimestral ? (
                  <CheckCircleRounded className={styles.planIcon} />
                ) : (
                  <CancelRounded
                    className={styles.planIcon + " " + styles.cancelIcon}
                  />
                )
              ) : (
                <p>{objPlanCell.content.trimestral}</p>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }

  const tablePlanCell = [
    {
      header: "Número de Vagas",
      content: {
        anualIlimitado: "12 vagas no ano",
        anual: "Vagas Ilimitadas",
        trimestral: "Ilimitado + 3 Vagas no Assessoria",
      },
    },
    {
      header: "Recorrência",
      content: {
        anualIlimitado: "Por 12 meses",
        anual: "Por 12 meses",
        trimestral: "Por 12 meses",
      },
    },
    {
      header: "Usuários na Plataforma",
      content: {
        anualIlimitado: "3",
        anual: "3",
        trimestral: "3",
      },
    },
    {
      header: "Banco de Currículos",
      content: {
        anualIlimitado: true,
        anual: true,
        trimestral: true,
      },
    },
    {
      header: "Testes Técnicos dos Talentos",
      content: {
        anualIlimitado: true,
        anual: true,
        trimestral: true,
      },
    },
    {
      header: "Fit Cultural dos Talentos",
      content: {
        anualIlimitado: true,
        anual: true,
        trimestral: true,
      },
    },
    {
      header: "Visualize o contato de até 30 talentos por vaga",
      content: {
        anualIlimitado: true,
        anual: true,
        trimestral: true,
      },
    },
    {
      header: "Página de Carreiras",
      content: {
        anualIlimitado: true,
        anual: true,
        trimestral: true,
      },
    },
    {
      header: "Envio das suas vagas 1x por mês para o banco de currículos",
      content: {
        anualIlimitado: false,
        anual: true,
        trimestral: true,
      },
    },
    {
      header: "Renovação de Vagas publicadas",
      content: {
        anualIlimitado: false,
        anual: true,
        trimestral: true,
      },
    },
    {
      header: "Descontos para vagas de Assesssoria",
      content: {
        anualIlimitado: false,
        anual: true,
        trimestral: true,
      },
    },
    {
      header: "Até 3 vagas Grátis no Assessoria",
      content: {
        anualIlimitado: false,
        anual: false,
        trimestral: true,
      },
    },
  ];

  const faqQuestionAnswers = [
    {
      question: "Posso acessar todos os currículos disponíveis?",
      answer:
        "Você pode acessar todos os currículos que tem como cargo pretendido o mesmo cargo da vaga que você tem aberta.",
    },
    {
      question: "Tenho acesso ao e-mail e telefone de toda a base?",
      answer:
        "Dentro da vaga pretendida, você pode olhar todos os currículos que temos disponíveis. Escolha os 20 melhores para aquela vaga e acesse o contato.",
    },
    {
      question: "Posso aplicar o meu teste técnico?",
      answer:
        "No portal disponibilizamos a biblioteca de testes com diversos temas. Caso estes não o atenda, pode aplicar outros direto com o talento e pelo meio que achar mais adequado.",
    },
    {
      question: "Posso contratar quantos talentos por vaga?",
      answer:
        "A abertura de vaga é para acesso aos currículos, o número de talentos contratados é controlado pela empresa.",
    },
    {
      question: "Qual plano tem o melhor benefício?",
      answer:
        "Varia de acordo com a necessidade da empresa, porém os planos mais completos são: Anual e Anual Ilimitado.",
    },
  ];

  function handleScrollPlan() {
    const elem = document.getElementById("plans");
    const elemRect = elem.getBoundingClientRect();
    const top = elemRect.top - 80;
    document.documentElement.scrollTo({
      top: top,
      behavior: "smooth",
    });
  }

  return (
    <Container disableGutters={true}>
      <Fab
        variant="extended"
        color="primary"
        href="https://wa.me/+5511957055684"
        sx={{
          position: "fixed",
          bottom: { xs: "80px", sm: "80px", md: "56px" },
          right: { xs: "16px", sm: "16px", md: "136px" },
          color: "#fff",
          textTransform: "none",
          fontWeight: "bold",
          fontFamily: "Open Sans",
        }}
      >
        <WhatsApp sx={{ mr: 1 }} />
        Fale com um consultor
      </Fab>
      <Container>
        <Grid
          container
          paddingTop={{ xs: 5, sm: 5, md: 20 }}
          paddingBottom={{ xs: 5, sm: 5, md: 10 }}
          columnSpacing={3}
          flexDirection={{ md: "row-reverse" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            sx={{
              aspectRatio: { xs: "1/1", sm: "1/1", md: "auto" },
              backgroundImage: `url(${imgHero})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundOrigin: "content-box",
            }}
          ></Grid>
          <Grid container item xs={12} sm={12} lg={6} rowGap={7}>
            <Grid container item rowGap={1}>
              <Grid item>
                <h1 className={"disableMarginBlock"}>
                  Mais de 75mil talentos esperam a sua vaga
                </h1>
              </Grid>
              <Grid item>
                <h6 className="disableMarginBlock" style={{ color: "#666" }}>
                  Produto exclusivo para as cidades de São Paulo, Belo Horizonte
                  e Rio de Janeiro
                </h6>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth={true}
                onClick={handleScrollPlan}
              >
                Conhecer Planos
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container py={10} alignItems="center">
          <Grid item lg={5} display={{ xs: "none", sm: "none", md: "flex" }}>
            <img
              width="100%"
              src={imgList}
              alt="Lista simulando filtro com um talento selecionado"
            />
          </Grid>
          <Grid item lg={1}></Grid>
          <Grid item lg={6}>
            <Grid container rowGap={8}>
              {itemList.map((e) => {
                return mountItemList(e.icon, e.title, e.description);
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container disableGutters={true}>
        <Grid id="plans" container py={10}>
          <Grid container item columnSpacing={{ xs: 0, lg: 3 }}>
            <Grid item lg={3}></Grid>
            {plans.map((plan) => {
              return mountPlans(plan);
            })}
          </Grid>

          <Grid
            id="anchor-table"
            container
            item
            xs={12}
            sm={12}
            lg={12}
            position="relative"
          >
            <Grid container item className={styles.planTable}>
              {tablePlanCell.map((e, i) => {
                return mountTablePlan(e, i, deviceScreenDownMd);
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Faq
          questionsAnswers={faqQuestionAnswers}
          color="secondary"
          btnText="Escolher Produto"
        />
        <CarrouselEnterprises />
      </Container>
    </Container>
  );
}

export default TalentsBank;
